<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar class="bg-dark">
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="irInicio" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">One Analítica</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ token.username }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" behavior="mobile" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <q-page-container class="fondo">
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"

export default {

    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    // created() {
    //     this.$store.dispatch("autoLogin");
    // },
    methods: {
        irInicio() {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>
<style>
.fondo {
    background-image: url('../../assets/photo-menu.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* Centra la imagen en el fondo */
    background-position: center;
    /* Establece un color de fondo de respaldo en caso de que la imagen no se cargue o no esté disponible */
    /* Establece una altura mínima para asegurarse de que la imagen cubra todo el contenido */
    min-height: 100vh;
    /* Ajusta otros estilos según sea necesario */
    margin: 0;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MainPage from '../components/Main/MainPage.vue'
import Login from '../views/Login.vue'
import store from '../store/index'

import MainClinic from '../components/Clinic/MainClinic.vue'
import ListaPacientes from '../components/Clinic/Pacientes/ListaPacientes.vue'
import ListaConsultas from '../components/Clinic/Consultas/ListaConsultas.vue'
import ListaEmpleados from '../components/Empleados/ListaEmpleados.vue'
import Constanza from '../components/Clinic/ChatGPT/Constanza.vue'
import Agenda from '../components/Clinic/Agenda/Agenda.vue'
import ListaConsultorios from '../components/Clinic/Consultorios/ListaConsultorios.vue'
import Perfil from '../components/Clinic/Perfil/Perfil.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: MainPage,
    children: [
      { path: 'Home', component: Home, name: 'Home', meta: { Administrador: true, Medico: true, Asistente: true } },
      { path: 'ListaEmpleados', component: ListaEmpleados, name: 'ListaEmpleados', meta: { Administrador: true } },
      { path: 'Perfil', component: Perfil, name: 'Perfil', meta: { Administrador: true, Medico: true, Asistente: false } },
    ],
  },
  {
    path: '/MainClinic', component: MainClinic,
    children: [
      { path: 'ListaPacientes', component: ListaPacientes, name: 'ListaPacientes', meta: { Administrador: true, Medico: true, Asistente: false } },
      { path: 'ListaEmpleados', component: ListaEmpleados, name: 'ListaEmpleados', meta: { Administrador: true, Asistente: false } },
      { path: 'ListaConsultas', component: ListaConsultas, name: 'ListaConsultas', meta: { Administrador: true, Medico: true, Asistente: false } },
      { path: 'Constanza', component: Constanza, name: 'Constanza', meta: { Administrador: true, Medico: true, Asistente: true } },
      { path: 'Agenda', component: Agenda, name: 'Agenda', meta: { Administrador: true, Medico: true, Asistente: true } },
      { path: 'ListaConsultorios', component: ListaConsultorios, name: 'ListaConsultorios', meta: { Administrador: true, Medico: true, Asistente: true } },
      { path: 'Perfil', component: Perfil, name: 'Perfil', meta: { Administrador: true, Medico: true, Asistente: true } },
    ],
  },
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.group == 'admin') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.group == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.group == 'Médico') {
    if (to.matched.some(record => record.meta.Medico)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.group == 'Asistente') {
    if (to.matched.some(record => record.meta.Asistente)) {
      next()
    }
  }
  else {
    next({
      name: 'Login'
    })
  }
})

export default router

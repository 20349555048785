<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-btn round dense style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close"
                    @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Empleado
                </q-toolbar-title>
                <q-btn v-if="empleado._id == ''" round dense style="background:#1A4161; color:white" class="q-mr-sm"
                    icon="mdi-content-save" @click="guardarEmpleado()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn v-else round dense style="background:#1A4161; color:white" class="q-mr-sm"
                    icon="mdi-content-save" @click="PutEmpleado()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12  ">
                        <q-input v-model="empleado.username" dense filled label="Nombre de usuario" />
                    </div>

                    <div class="col-12 ">
                        <q-input v-model="empleado.nombre" dense filled label="Nombre" />
                    </div>
                    <div class="col-12  ">

                        <q-input v-model="empleado.apellido" dense filled label="Apellidos" />
                    </div>
                    <div class="col-12  ">
                        <q-select dense class="full-width" v-model="empleado.genero" filled
                            :options="['Femenino', 'Masculino']" label="Genero" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="empleado.telefono" dense filled label="Teléfono" />
                    </div>
                    <div class="col-12  ">
                        <q-input v-model="empleado.email" dense filled label="Email" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="fechaMasked" filled label="Fecha de nacimiento" name="event" outlined dense>
                            <template v-slot:before>
                                <q-icon name="event" color="primary" />
                            </template>
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="empleado.fechaNacimiento">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-input>
                    </div>
                    <div class="col-12  ">
                        <q-input dense v-model="empleado.password" filled :type="isPwd ? 'password' : 'text'"
                            label="Password">
                            <template v-slot:append>
                                <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                                    @click="isPwd = !isPwd" />
                            </template>
                        </q-input>
                    </div>
                    <div class="col-12 ">
                        <q-select dense class="full-width" v-model="empleado.group" filled
                            :options="['Administrador', 'Médico', 'Asistente']" label="Rol" />
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {
            isPwd: true,
        }
    },
    computed: {
        empleado() {
            return this.$store.state.empleadoStore;
        },
        fechaMasked() {
            moment.locale('es-mx');
            return this.empleado.fechaNacimiento ? moment.utc(this.empleado.fechaNacimiento).format('DD/MMMM/yyyy') : ''
        },
    },

    created() {
    },
    methods: {
        async guardarEmpleado() {

            console.log(this.empleado)
            if (this.empleado.nombreuser == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del usuario' })
                return;
            }
            if (this.empleado.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su nombre' })
                return;
            }
            if (this.empleado.apellido == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique sus apellidos' })
                return;
            }
            if (this.empleado.password == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el password' })
                return;
            }
            if (this.empleado.genero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su genero' })
                return;
            }
            if (this.empleado.rol == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el rol del usuario' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando empleado. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('Login/PostEmpleado/dev/', this.empleado)
                console.log(response)

                this.empleado._id = response.data
                this.$store.state.listaEmpleadosStore.push(this.empleado)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El empleado ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async PutEmpleado() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando empleado. Espere...', messageColor: 'white' })
            if (this.empleado.nombreuser == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del usuario' })
                return;
            }
            if (this.empleado.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su nombre' })
                return;
            }
            if (this.empleado.apellido == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique sus apellidos' })
                return;
            }
            if (this.empleado.password == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el password' })
                return;
            }
            if (this.empleado.genero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su genero' })
                return;
            }
            if (this.empleado.rol == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el rol del usuario' })
                return;
            }
            try {
                let response = await axios.put('Login/PutEmpleado/dev', this.empleado)
                console.log(response)

                let indice = this.$store.state.listaEmpleadosStore.findIndex(x => x._id === this.empleado._id);
                Object.assign(this.$store.state.listaEmpleadosStore[indice], this.empleado)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El empleado ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },
    }
}
</script>
<style></style>